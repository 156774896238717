<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" md="6" class="d-none d-lg-flex p-0">
        <SplashScreen />
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> MyFlight Connect </b-card-title>
          <b-card-text class="mt-2">
            <div>
              <p>{{ $t('login.forgot_password_validation_text') }}</p>
            </div>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact' }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SplashScreen from '@/views/base/SplashScreen.vue'

export default {
  name: 'ForgotPasswordValidation',
  components: {
    SplashScreen,
  },

  data() {
    return {
      validationLoading: false,
      sideImg: require('@/assets/images/pages/login/airplane.jpg'),
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },

  computed: {
    ...mapState('appConfig', ['layout']),
    imgUrl() {
      if (this.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/airplane-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
